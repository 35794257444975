import React from 'react';

const BoardDirectorsTitle = props => {
    return (
        <div className="text-center mb-4">
            <img src={props.imgsrc} className="board-headshot" />
            <h5>
                <div className="fw-semibold">{props.title}</div>
                <small className="text-dark">{props.subtitle}</small><br />
                <div className="mt-2"><small>{props.text}</small></div>
            </h5>
        </div>
    );
};

export default BoardDirectorsTitle