import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import flag from '../images/flag.webp';

const flagImages = [
    flag, flag, flag, flag, flag, flag, flag,
];

const MultipleLocation = () => {

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % flagImages.length);
        }, 1000); // Change the interval as needed (in milliseconds)

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="section section-home-alternate">
            <div className="section multiplelocation">

                <div className="object multiple-locations">
                    <div className="flag-container">
                        {flagImages.map((image, index) => (
                            <img
                                key={index}
                                src={image}
                                alt={`Flag ${index + 1}`}
                                className={`flag ${currentIndex >= index ? 'visible' : ''}`}
                            />
                        ))}
                    </div>
                </div>
                <Container>
                    <div className="section-content home-section-content">
                        <h3>Multiple locations? <br /> Complex processes?<br /> No problem.</h3>
                        <p>openDoctor is thoughtfully designed to handle all healthcare workflows regardless of the complexity.</p>
                        <NavLink to="/customers"><Button className="button large primary">Case Studies</Button></NavLink>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default MultipleLocation;