import React, { useState, useEffect } from 'react';
import { Container, Navbar, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import NavMenu from './NavMenu';
import logo from '../images/ODlogo.png';

const Header = () => {
    const [sticky, setSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 10)
        }
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    return (
        <header className={`${sticky ? "sticky" : ""}`}>
            {<Navbar expand="lg" className='p-0 header-top blureffect'>
                <Container fluid className="px-lg-5 px-sm-4 py-2 header-box">
                    <NavLink to="/"><img className='logo' src={logo} alt="openDoctor" width={180} /></NavLink>
                    <NavMenu />
                    <div className='d-none d-lg-block'><NavLink to="/contact-us"><Button variant="warning" size="sm" className="my-2 text-white text-uppercase appt-btn flot-end">Contact Us</Button></NavLink></div>
                </Container>
            </Navbar>}
            <div className='advtextborder'>
                <div className='advtext'>
                    If you wish to see openDoctors in action and understand how it can help your organization, schedule a <a href="https://calendly.com/ahulugalle" rel="noreferrer" target="_blank">time here</a>.
                    {/*
                    OpenDoctors will be at RSNA 2023.
                    Visit us at the North Hall Booth #6544 or <a href="https://calendar.app.google/BcaHch7Z9wrJB4C89" rel="noreferrer" target="_blank">schedule</a>.
                    */}
                </div>
            </div>
        </header >
    )
}
export default Header