import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TeamBlock from './TeamBlock';
//import BoardDirectors from './BoardDirectors';
import BoardDirectorsTitle from './BoardDirectorsTitle';
import team1 from '../images/team/Joe.jpeg';
import team2 from '../images/team/Oliver.jpg';
import team3 from '../images/team/Avanthi.jpeg';
import team4 from '../images/team/Yojana.jpg';
import team6 from '../images/team/Mitesh.jpeg';
import team7 from '../images/team/Sachin.jpg';
import team8 from '../images/team/Rajkumar.jpg';
import team9 from '../images/team/Kaitlyn.png';
import logo1 from '../images/logos/RamSoft.jpg';
import logo2 from '../images/logos/Merative_logo_color_RGB_TM.png';
import logo3 from '../images/logos/imagine-logo-black-250px.png';
import logo4 from '../images/logos/infinx_logo.png';
import logo5 from '../images/logos/Avreo.png';

const TeamContent = () => {
    return (
        <div className='border-top'>
            <Container fluid className='p-5 pb-0'>
                <h4 className="fw-semibold text-center mb-5">Meet our leadership team</h4>
                <Row>
                    <TeamBlock imgsrc={team1} title="Joseph Marino" subtitle="Chief Executive Officer" text="Joe joined openDoctor to lead the company's sales, business development, partnerships and marketing programs. Joe transitioned into his current role as Chief Executive Officer mid-2015 and brings over 20 years of experience in building business, creating culture and driving positive outcomes at both start-up and established companies. In his free time, Joe can be seen running along NYC's west side pathways and coaching championship girls' fast pitch softball teams." />
                    <TeamBlock imgsrc={team2} title="Oliver D'souza" subtitle="Chief Technology Officer" text="As the leader of our technical team, Oliver brings more than 14 years experience in the Healthcare IT and is responsible for the openDoctor product strategy and development. In his spare time, Oliver enjoys spending time with friends and family, traveling and experiencing new cultures, and cooking." />
                </Row>
                <Row>
                    <TeamBlock imgsrc={team3} title="Avanthi Hulugalle" subtitle="Director, Client Success and Product Strategy" text="Avanthi joined openDoctor to ensure client success and continue to develop high-quality user experience. She has a Masters in Public Health from NYU and is passionate about improving healthcare. In her free time, you can find her exploring New York City or playing sports in the park." />
                    <TeamBlock imgsrc={team4} title="Yojana Dsouza" subtitle="Senior Account Manager" text="Yojana plays an essential role in developing long-term relationships with openDoctor clients. She ensures our clients receive the highest quality and reliable services. In her free time, she loves traveling, reading and music." />

                </Row>
                <Row className="justify-content-md-left">
                    <TeamBlock imgsrc={team6} title="Mitesh Parwani" subtitle="Director of Professional Services" text="As the Director of Professional Services, Mitesh is a valuable member of the openDoctor family. He has a Masters degree in Pharmacy from St. John’s University and has more than five years of experience in Healthcare IT. Mitesh brings fresh ideas to the table and believes in sharing his experiences. He is an athlete in his free time, loves to socialize and lives on a carb free diet. His motto in life is, 'If you want to learn something new, just listen carefully'." />
                    <TeamBlock imgsrc={team7} title="Sachin Puranik" subtitle="Software Architect" text="Sachin is passionate about building scalable solutions, health technology innovations and the application of applying to openDoctor clients. When outside the office, Sachin enjoys quality time with family, reading and music." />

                </Row>
                <Row className="justify-content-md-left">
                    <TeamBlock imgsrc={team8} title="Rajkumar Adsule" subtitle="IT Admin Lead" text="Rajkumar leads our Information Technology admin team. He brings more than 10 years of experience in IT. He is RedHat Certified. He loves to spend his free time with family and friends. He also likes listening to music and playing chess." />
                    <TeamBlock imgsrc={team9} title="Kaitlyn Bradley" subtitle="Project Manager" text="Kaitie joined the openDoctor team in 2019. She is part of the project management team and assists clients with implementation and organizing their practice data. She has 2 years of experience in the healthcare industry and holds a BS in Health Policy and Administration from Pennsylvania State University. In her free time she enjoys going to see musicals and exploring the city with friends. " />
                </Row>
            </Container>

            {/*  <BoardDirectors />  */}

            <Container className="text-center pt-5 pb-3 border-top">
                <div className="mb-4"><h4 className="fw-semibold">Product Advisors</h4></div>
                <Row className="justify-content-md-center">
                    <Col md={3} className="mb-4">
                        <BoardDirectorsTitle title="Trish Dunn" subtitle="RIS Learning & Development Facilitator" text="South Jersey Radiology Associates" />
                    </Col>
                    <Col md={3} className="mb-4">
                        <BoardDirectorsTitle title="Murray Becker" subtitle="President" text="University Radiology" />
                    </Col>
                    <Col md={3} className="mb-4 px-0">
                        <BoardDirectorsTitle title="Alberto Goldszal" subtitle="Chief Operating Officer" text="University Radiology" />
                    </Col>
                </Row>
            </Container>

            <Container fluid className="text-center light-gray p-5">
                <div className="mb-5"><h4 className="fw-semibold">Our Partners</h4></div>
                <Row className="justify-content-md-center text-center">
                    <Col lg={3} className="mb-3"><img src={logo1} width="150px" alt="RamSoft" /></Col>
                    <Col lg={3} className="mb-3"><img src={logo2} width="150px" alt="Merative" /></Col>
                    <Col lg={3} className="mb-3"><img src={logo3} width="150px" alt="Imagine Software" /></Col>
                </Row>
                <Row className='justify-content-md-center text-center pt-md-4'>
                    <Col lg={3} className="mb-3"><img src={logo4} width="144px" alt="Canopy Partners" /></Col>
                    <Col lg={3}><img src={logo5} width="150px" alt="Avreo" /></Col>
                </Row>
            </Container>
        </div>
    )
}

export default TeamContent