import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import cloud from '../images/medium_cloud.webp';
import cloud_outlet from '../images/cloud_outlet.webp';
import homepage_plug from '../images/homepage_plug.webp';

const HomeAnimationIntegration = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Adjust the factor to control the speed of the parallax effect
    const parallaxFactorWeb = 3; // You can adjust this value
    const parallaxFactorMobile = 10; // You can adjust this value

    return (
        <div class="section section-border integration">
            <div className='d-none d-lg-block'>
                <div className="left-cloud" style={{ transform: `translateX(${scrollPosition / parallaxFactorWeb}px)` }}><img src={cloud} width="275px" alt="Seamless Integration" /></div>
                <div className="cloud-outlet" style={{ transform: `translateX(${scrollPosition / parallaxFactorWeb}px)` }}><img src={cloud_outlet} width="550px" alt="Seamless Integration" /></div>
                <div className="right-cloud" style={{ transform: `translateX(-${scrollPosition / parallaxFactorWeb}px)` }}><img src={cloud} width="170px" alt="Seamless Integration" /></div>
                <div className="homepage_plug" style={{ transform: `translateX(-${scrollPosition / parallaxFactorWeb}px)` }}><img src={homepage_plug} width="750px" alt="Seamless Integration" /></div>
            </div>
            <div className='d-block d-lg-none'>
                <div className="left-cloud" style={{ transform: `translateX(${scrollPosition / parallaxFactorMobile}px)` }}><img src={cloud} width="140px" alt="Seamless Integration" /></div>
                <div className="cloud-outlet" style={{ transform: `translateX(${scrollPosition / parallaxFactorMobile}px)` }}><img src={cloud_outlet} width="240px" alt="Seamless Integration" /></div>
                <div className="right-cloud" style={{ transform: `translateX(-${scrollPosition / parallaxFactorMobile}px)` }}><img src={cloud} width="110px" alt="Seamless Integration" /></div>
                <div className="homepage_plug" style={{ transform: `translateX(-${scrollPosition / parallaxFactorMobile}px)` }}><img src={homepage_plug} width="290px" alt="Seamless Integration" /></div>
            </div>
            <div className="container">
                <div className="section-content home-section-content">
                    <h3>Seamless Integration</h3>
                    <p>openDoctor is cloud based, so there's no software to install. It works in real-time with your existing systems, and fits within your workflows... not ours.</p>
                    <NavLink to="/integration"><Button className="button large primary">Integration</Button></NavLink>
                </div>
            </div>
        </div>
    )
}
export default HomeAnimationIntegration;
