import React, { useState } from 'react';
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const NavMenu = () => {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };
    return (
        <>
            <button onClick={toggleMobileMenu} className="navbar-toggler">
                <span className='navbar-toggler-icon'></span>
            </button>

            <Navbar.Collapse id="basic-navbar-nav" className={`justify-content-end header-link nav-links ${isMobileMenuOpen ? 'open' : ''}`}>
                <nav-link>
                    <NavLink onClick={closeMobileMenu} to="/features">Features</NavLink>
                    <NavLink onClick={closeMobileMenu} to="/integration">Integration</NavLink>
                    <NavLink onClick={closeMobileMenu} to="/customers">Customers</NavLink>
                    <NavLink onClick={closeMobileMenu} to="/roi">ROI</NavLink>
                    <NavLink onClick={closeMobileMenu} to="/company">Company</NavLink>
                    <NavLink onClick={closeMobileMenu} className='d-block d-lg-none' to="/news-updates">Blog</NavLink>
                    <NavLink onClick={closeMobileMenu} className="morelink d-block d-lg-none" to="/contact-us">Contact us</NavLink>
                </nav-link>
            </Navbar.Collapse>
        </>
    )
}

export default NavMenu