import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Container, Button } from 'react-bootstrap';
import HomeAnimationIntegration from './HomeAnimationIntegration';
import HomeAnimation from './HomeAnimation';
import chair from '../images/chair.webp';
import icon1 from '../images/Paperless-image.webp';
import icon2 from '../images/Flex-Calendar-1.webp';
import icon3 from '../images/Patient-billing-1.webp';
import icon4 from '../images/Mobile-Check-1.webp';
import icon5 from '../images/Insurance-1.webp';
import icon6 from '../images/Appointment-1.webp';
import icon7 from '../images/Multiple-Locations-1.webp';


const HomeAnimationBlock = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Adjust the factor to control the speed of the parallax effect
    const parallaxFactorWeb = 8; // You can adjust this value
    //const parallaxFactorMobile = 10; // You can adjust this value

    const images = [
        icon1, icon2, icon3, icon4, icon5, icon6, icon7,
    ];
    return (
        <Container fluid className="text-center p-0">
            <HomeAnimationIntegration />

            <div className="section less-no-show">
                <div className="section-object wall" ></div>
                <div className="chair_move" style={{ transform: `translateY(-${scrollPosition / parallaxFactorWeb}px)` }}><img src={chair} width="475px" alt="More patients, less no-shows" /></div>
                <div className="container">
                    <div className="home-section-content">
                        <h3>More patients,<br />less no-shows</h3>
                        <p>Cut no-shows, see more patients, and eliminate call center inefficiencies.</p>
                        <NavLink to="/roi"><Button className="button large primary">See potential savings</Button></NavLink>
                    </div>
                </div>
            </div>

            <div className="section patients-section patients-adapt-section">
                <div className="container ">
                    <div className="home-section-content">
                        <h3>Patients live online.<br />Are you accessible?</h3>
                        <p>Consumers demand real-time access from healthcare just as they receive from Amazon, Expedia, Uber and every other industry.</p>
                    </div>
                    <NavLink to="/contact-us"><Button className="button large orange">Take the first step</Button></NavLink>
                </div>
                <HomeAnimation images={images} interval={4000} />
            </div>
        </Container>
    )
}

export default HomeAnimationBlock