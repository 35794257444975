import React from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import screen1 from '../images/screen1.jpg';
import screen2 from '../images/screen2.jpg';
import screen3 from '../images/screen3.jpg';
import screen4 from '../images/screen4.jpg';

function HomeAnimationFeatures() {
    return (
        <div className="section section-mobile">
            <div class="container">
                <h3 className="text-center mb-5">It's not just appointments</h3>
                <div className="object mobile-phone"></div>
                <div class="object mobile-phone-display">
                    <div class="display-wrapper">
                        <img src={screen1} alt="openDoctor" />
                        <img src={screen2} alt="openDoctor" />
                        <img src={screen3} alt="openDoctor" />
                        <img src={screen4} alt="openDoctor" />
                    </div>
                </div>
                <div className="icon-object icon-mo1 active"></div>
                <div className="icon-object icon-mo2"></div>
                <div className="icon-object icon-mo3"></div>
                <div className="icon-object icon-mo4"></div>
            </div>
            <div className="container">
                <div className="section-content home-section-content">
                    <p>Fully automate your consumer experience with smart forms, reminders, payments, and much more...</p>
                    <NavLink to="/features"><Button className="button large primary">View all features</Button></NavLink>
                </div>
            </div>
        </div>
    );
}
export default HomeAnimationFeatures