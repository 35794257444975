import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Features from './Features';
import Integration from './pages/Integration';
import Customers from './pages/Customers';
import Roi from './pages/Roi';
import Company from './pages/Company';
import Careers from './pages/Careers.js';
import NewsUpdates from './pages/NewsUpdates';
import NewsUpdatesContent1 from './pages/NewsUpdatesContent1';
import NewsUpdatesContent2 from './pages/NewsUpdatesContent2';
import NewsUpdatesContent3 from './pages/NewsUpdatesContent3';
import NewsUpdatesContent4 from './pages/NewsUpdatesContent4';
import NewsUpdatesContent5 from './pages/NewsUpdatesContent5';
import NewsUpdatesContent6 from './pages/NewsUpdatesContent6';
import NewsUpdatesContent7 from './pages/NewsUpdatesContent7';
import NewsUpdatesContent8 from './pages/NewsUpdatesContent8';
import NewsUpdatesContent9 from './pages/NewsUpdatesContent9';
import NewsUpdatesContent10 from './pages/NewsUpdatesContent10';
import NewsUpdatesBlog1 from './pages/NewsUpdatesBlog1';
import NewsUpdatesBlog2 from './pages/NewsUpdatesBlog2';
import NewsUpdatesCaseStudy1 from './pages/NewsUpdatesCaseStudy1';
import NewsUpdatesCaseStudy2 from './pages/NewsUpdatesCaseStudy2';
import NewsUpdatesNews1 from './pages/NewsUpdatesNews1';
import NewsUpdatesNews2 from './pages/NewsUpdatesNews2';
import NewsUpdatesNews3 from './pages/NewsUpdatesNews3';
import NewsUpdatesNews4 from './pages/NewsUpdatesNews4';
import NewsUpdatesUpdates1 from './pages/NewsUpdatesUpdates1';
import NewsUpdatesUpdates2 from './pages/NewsUpdatesUpdates2';
import NewsUpdatesUpdates3 from './pages/NewsUpdatesUpdates3';
import NewsUpdatesUpdates4 from './pages/NewsUpdatesUpdates4';
import ContactUs from './pages/ContactUs';
import Faq from './pages/Faq';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Inquiry from './pages/Inquiry';
import ProjectManager from './pages/ProjectManager';
import QualityAssuranceTester from './pages/QualityAssuranceTester';
import SeniorPhpDeveloper from './pages/SeniorPhpDeveloper';
import BusinessAnalystIntern from './pages/BusinessAnalystIntern';
import InterfaceDeveloperIntern from './pages/InterfaceDeveloperIntern';
import FullStackSoftwareDeveloper from './pages/FullStackSoftwareDeveloper';
import AutomationTestEngineer from './pages/AutomationTestEngineer';
import EnterpriseSolution from './pages/EnterpriseSolution';
import RemindersNotifications from './pages/RemindersNotifications';
import DigitalFormsCheckIn from './pages/DigitalFormsCheckIn';
//import TawkTo from 'tawkto-react';
//import TawkTo from './TawkTo';


function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
          <Route exact path="/integration" element={<Integration />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/roi" element={<Roi />} />
          <Route path="/company" element={<Company />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/news-updates" element={<NewsUpdates />} />
          <Route path="/news-updates/opendoctor-and-steinberg-diagnostic-join-forces-for-ordering-and-scheduling-automation" element={<NewsUpdatesContent1 />} />
          <Route path="/news-updates/philips-and-opendoctor-partner-to-deliver-unprecedented-digital-front-door-experience-in-radiology" element={<NewsUpdatesContent2 />} />
          <Route path="/news-updates/infinx-healthcare-and-opendoctor-partner-on-an-innovative-approach-to-patient-access" element={<NewsUpdatesContent3 />} />
          <Route path="/news-updates/tower-radiology-and-opendoctor-partner-for-online-scheduling" element={<NewsUpdatesContent4 />} />
          <Route path="/news-updates/opendoctor-adds-virtual-registration-client" element={<NewsUpdatesContent5 />} />
          <Route path="/news-updates/imaginesoftware-and-opendoctor-announce-a-strategic-partnership-streamlining-patient-engagement" element={<NewsUpdatesContent6 />} />
          <Route path="/news-updates/an-important-statement-regarding-covid-19-opendoctor-is-here-to-help" element={<NewsUpdatesContent7 />} />
          <Route path="/news-updates/siim-2020-to-feature-opendoctor-production-improvement-by-patient-online-appointment-scheduling-abstract" element={<NewsUpdatesContent8 />} />
          <Route path="/news-updates/scriptsender-partners-with-opendoctor-to-deliver-streamlined-scheduling-workflow" element={<NewsUpdatesContent9 />} />
          <Route path="/news-updates/medcurrent-partners-with-opendoctor-to-enable-seamless-radiology-clinical-decision-support-for-ordering-providers" element={<NewsUpdatesContent10 />} />
          <Route path="/news-updates/text-reminders-prove-to-reduce-no-show-rates-for-imaging-services" element={<NewsUpdatesBlog1 />} />
          <Route path="/news-updates/opendoctor-hosts-online-symposium-focused-on-consumerism-in-radiology" element={<NewsUpdatesBlog2 />} />
          <Route path="/news-updates/how-do-high-deductible-health-plans-affect-radiology" element={<NewsUpdatesCaseStudy1 />} />
          <Route path="/news-updates/university-radiology-makes-strategic-investment-in-opendoctor" element={<NewsUpdatesCaseStudy2 />} />
          <Route path="/news-updates/patient-portal-adoption-tops-90-patient-registration-lags" element={<NewsUpdatesNews1 />} />
          <Route path="/news-updates/welch-road-imaging-integrates-ramsoft-powerserver-ris-pacs-opendoctor" element={<NewsUpdatesNews2 />} />
          <Route path="/news-updates/what-matters-most-to-the-healthcare-consumer" element={<NewsUpdatesNews3 />} />
          <Route path="/news-updates/opendoctor-lands-covia-health-agreement" element={<NewsUpdatesNews4 />} />
          <Route path="/news-updates/opendoctor-partners-zwanger-pesiri-real-time-patient-online-scheduling" element={<NewsUpdatesUpdates1 />} />
          <Route path="/news-updates/patient-engagement-forefront-opendoctor-partnership-steinberg-diagnostic" element={<NewsUpdatesUpdates2 />} />
          <Route path="/news-updates/why-patients-wont-request-an-appointment-much-longer" element={<NewsUpdatesUpdates3 />} />
          <Route path="/news-updates/what-does-consumerism-in-healthcare-look-like" element={<NewsUpdatesUpdates4 />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/inquiry" element={<Inquiry />} />
          <Route path="/careers/project-manager" element={<ProjectManager />} />
          <Route path="/careers/quality-assurance-tester" element={<QualityAssuranceTester />} />
          <Route path="/careers/senior-php-developer" element={<SeniorPhpDeveloper />} />
          <Route path="/careers/business-analyst-intern" element={<BusinessAnalystIntern />} />
          <Route path="/careers/interface-developer-intern" element={<InterfaceDeveloperIntern />} />
          <Route path="/careers/full-stack-software-developer" element={<FullStackSoftwareDeveloper />} />
          <Route path="/careers/automation-test-engineer" element={<AutomationTestEngineer />} />
          <Route path="/enterprise-solution" element={<EnterpriseSolution />} />
          <Route path="/reminders-notifications" element={<RemindersNotifications />} />
          <Route path="/digital-forms-checkin" element={<DigitalFormsCheckIn />} />
        </Routes>

        <ScrollToTop />

        <Footer />
        {/*<TawkTo />*/}

      </BrowserRouter>
    </>
  );
}

export default App;
