import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import LinkMailto from '../components/LinkMailto';
import PageBanner from '../components/PageBanner';
//import Loading from '../components/Loading';
//import { validateEmail, validateFullName, validateMessage, } from '../components/Validation';
//import { saveAs } from 'file-saver';

const ContactUs = () => {

  const [state, setState] = useState({
    fullName: '',
    email: '',
    subject: '',

    message: ''
  });

  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [result, setResult] = useState(null);

  const sendEmail = event => {
    event.preventDefault();
    setButtonLoading(true);
    axios
      .post('/send', { ...state })
      .then(response => {
        setResult(response.data);
        setButtonLoading(false);
        setState({
          fullName: '',
          email: '',
          message: '',
          subject: ''
        });
      })
      .catch(() => {
        //var FileSaver = require('file-saver');
        //var blob = new Blob(["Hello, world!"], { type: "text/plain;charset=utf-8" });
        //FileSaver.saveAs(blob, "response.txt");


        setResult({
          success: false,
          message: 'oops!!! , Email not sent. Try again later...'
        });
        setButtonLoading(false);
      });
  };

  const onInputChange = event => {
    const { name, value } = event.target;

    setState({
      ...state,
      [name]: value
    });
  };
  return (
    <>

      <div>
        <PageBanner title="Contact Us" />
        <Container fluid className='p-5'>
          <Row className="justify-content-md-center">
            <Col lg={8} xs={12}>
              <Row>
                <Col>
                  <h5 className='text-center mb-5 fw-semibold'>Send us a message by filling out the form below!</h5>
                  {result && (
                    <p style={{ color: 'red' }} className={`${result.success ? 'success' : 'error'}`}>
                      {result.message}
                    </p>
                  )}
                  <Form onSubmit={sendEmail}>
                    <Form.Floating className='mb-4'>
                      <Form.Control value={state.fullName}
                        onChange={onInputChange} id="fullName" name="fullName" type="text" placeholder="Your full name" required />
                      <label htmlFor="FullName">Your full name</label>
                      <Form.Control.Feedback type="invalid">Please enter your fullname.</Form.Control.Feedback>
                    </Form.Floating>

                    <Form.Floating className='mb-4'>
                      <Form.Control value={state.email}
                        onChange={onInputChange} id="emailAddress" name="email" type="email" placeholder="Your email address" required />
                      <label htmlFor="emailAddress">Your email address</label>

                      <Form.Control.Feedback type="invalid">Email address is required.</Form.Control.Feedback>
                    </Form.Floating>

                    <Form.Floating className='mb-4'>
                      <Form.Control value={state.subject} onChange={onInputChange} id="subject" name="subject" type="text" placeholder="subject" />
                      <label htmlFor="subject">Subject</label>
                    </Form.Floating>

                    <Form.Floating className='mb-4'>
                      <Form.Control value={state.message}
                        onChange={onInputChange} id="message" name="message" as="textarea" rows={3} placeholder="Type your message here..." required />
                      <label htmlFor="message">Type your message here...</label>

                    </Form.Floating>
                    <div className='text-center'>
                      <Button type="submit" className='button large submit' disabled={buttonLoading && true}>{buttonLoading ? 'Sending..' : 'SUBMIT'}</Button>
                    </div>

                  </Form>

                </Col>
              </Row>
              <Row className='mt-5'>
                <Col lg={6} sm={12}>
                  <h5 className='fw-semibold text-uppercase'>New York</h5>
                  <p>
                    33 Park PL<br />
                    S110, New York, NY 10007<br />
                    Phone: +1 347 448 4997<br />
                    <LinkMailto label="contactus@opendr.com" mailto="mailto:contactus@opendr.com" />
                  </p>
                </Col>
                <Col lg={6} sm={12}>
                  <h5 className='fw-semibold text-uppercase'>Mumbai</h5>
                  <p>
                    Raheja Platinum Building<br />
                    1st Floor, Office number - 01A127 & 01A126<br />
                    Sag Baug Rd, Off Andheri-Kurla Rd, Marol<br />
                    Andheri East, Mumbai - 400059<br />
                    {/*Phone: <Nav.Link href="tel:+91 02225973290" className='me-3 d-inline'>+91 02225973290</Nav.Link>*/}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>


    </>
  );
}

export default ContactUs
